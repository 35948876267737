import React, {useEffect} from "react"
import { FaUser, FaStoreAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"
import {selectCategory} from "../redux/registration/registrationAction"
import {getCategories} from "../redux/api/apiAction"

const MemberPage = () => {
    const dispatch = useDispatch()
    const {categories} = useSelector(({apiReducer}) => apiReducer)

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    const onClick = (nonMember = true) => {
        dispatch(selectCategory(categories, nonMember ? "Delegate" : "UNION Member"))
    }

    return (
        <Layout>
            <SEO title="Registration" />
        <div className={"col-10 mx-auto"}>
        <p className={"txt-left"}><small>Membership to The Union means you are part of a movement of professionals who work together to drive positive action for lung health. We help give your work a voice in the halls of government and in the news media as we drive a global advocacy agenda.<br/>We work tirelessly to help ensure your work saves more lives and empowers survivors.<br/>Alongside a range of benefits Union members receive a 100-euro discount on a full four-day registration for the Union World Conference, or a 50-euro discount if you benefit from a reduced conference registration rate.</small></p><p><small><a href="https://www.theunion.org/get-involved/join" target="_blank">Join now</a> to take advantage of all of the member benefits and stand in Union together against lung disease.</small></p>
           </div>
        <div className={"card-list"}>
                <Card title={"Non member"} link={"non-member-single"} onClick={() => onClick()}/>
                <Card title={"Union member"} link={"union-member-single"} onClick={() => onClick(false)}/>
            </div>
        </Layout>
    )
}

export default MemberPage
